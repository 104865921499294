// import PageHeader from '@/components/PageHeader'
// import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// import tableMixin from '@/minxin/table-minxin' tableMixin
import root from '@/minxin/root'

const Index = {
  name: 'accountInfo',
  mixins: [root],
  data() {
    var validatePass = this.validatePass
    return {
      root_name: 'zhxx',
      root_msg: '账号信息',
      showSetInfo: false,
      ruleForm: {
        CompanyName: '暂无数据',
        NickName: '暂无数据',
        Mobile: '暂无数据',
        OfficeTel: '暂无数据',
        EMail: '暂无数据',
        QQ: '暂无数据',
        Sex: null
      },
      rules: {
        NickName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        Mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        EMail: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]
      },
      isWait: false,
      formJson: {
        CompanyName: '暂无数据',
        NickName: '暂无数据',
        UserName: '暂无数据',
        UserClassString: '暂无数据',
        WriteTime: '暂无数据',
        PermissionGroupInfoList: [],
        ExeID: '暂无数据',
        WorkNum: '暂无数据',
        SexShow: '暂无数据',
        Mobile: '暂无数据',
        OfficeTel: '暂无数据',
        EMail: '暂无数据',
        QQ: '暂无数据',
        Sex: null
      }
    }
  },
  mounted() {
    this.GetCurrentUserInfo()
  },
  methods: {
    // 验证手机号
    validatePass(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!this.$minCommon.checkMobile(value)) {
        callback(new Error('请输入正确的手机号'))
      }
      callback()
    },
    // 获取信息
    GetCurrentUserInfo() {
      this.$api.GetCurrentUserInfo().then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.formJson = res.Message
      })
    },
    edit() {
      this.showSetInfo = true
      for (const key in this.ruleForm) {
        this.ruleForm[key] = this.formJson[key]
      }
    },
    close(e) {
      this.showSetInfo = false
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        this.isWait = true
        console.log(this.ruleForm)
        this.$api.UpdateCurrentUserInfo(this.ruleForm).then(res => {
          console.log(res)
          this.isWait = false
          if (res.RetCode !== '0') {
            return this.$message.error('错误：' + res.RetMsg)
          }
          this.$message.success('修改成功')
          this.showSetInfo = false
          // 如果提交成功 重置表单并重新获取数据
          this.$refs[formName].resetFields()
          this.GetCurrentUserInfo()
        }).catch(err => {
          this.isWait = false
          return this.$message.error('错误：' + err)
        })
      })
    }
  }
}

export default Index
