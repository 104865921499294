<template>
  <div class="accountInfo p-lr-20 p-tb-20">

    <div class="userinfo_header flex column" style="height:260px">
      <div class="header p-lr-20 flex j-between a-center">
        <div class="weight">账号信息</div>
      </div>
      <div class="flex ">
        <div class="header-icon m-top-20" />
        <div class="main_view p-lr-20 flex column">
          <span class="f18 p-lr-20 weight m-top-20">{{ formJson.CompanyName }}，{{ formJson.NickName }}你好! </span>
          <div class="flex j-start p-lr-30 a-center">
            <div class="item_main flex ">
              <div class="item title f12 p-right-10">登录账号: </div>
              <div class="item value f12">{{ formJson.UserName }}</div>
            </div>
            <div class="item_main flex ">
              <div class="item title f12 p-right-10">账号角色: </div>
              <div class="item value f12">{{ formJson.UserClassString }}</div>
            </div>
          </div>
          <div class="flex j-start p-lr-30 a-center">
            <div class="item_main flex ">
              <div class="item title f12 p-right-10">创建时间: </div>
              <div class="item value f12">{{ formJson.WriteTime }}</div>
            </div>
            <div class="item_main flex ">
              <div class="item title f12 p-right-10">账号部门: </div>
              <div v-for="(item,index) in formJson.PermissionGroupInfoList" :key="item.IDX" class="item value f12">
                {{ item.GroupName }}<span v-if="index !== formJson.PermissionGroupInfoList.length -1">，</span>
              </div>
            </div>
          </div>
          <div class="flex j-start p-lr-30 a-center">
            <div class="item_main flex ">
              <div class="item title f12 p-right-10">用户编号: </div>
              <div class="item value f12">{{ formJson.ExeID }}</div>
            </div>
            <div class="item_main flex ">
              <div class="item title f12 p-right-10">账户工号: </div>
              <div class="item value f12">{{ formJson.WorkNum }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="userinfo_header flex column m-top-30">
      <div class="header p-lr-20 flex j-between a-center">
        <div class="weight">个人信息</div>
        <span class="theme-color  cursor" @click="edit">修改</span>
      </div>

      <div class="main_view p-lr-20 flex column p-top-30">
        <div class="flex j-start p-lr-30 a-center">
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">姓名: </div>
            <div class="item value f12">{{ formJson.NickName }}</div>
          </div>
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">性别: </div>
            <div class="item value f12">{{ formJson.SexShow }}</div>
          </div>
        </div>
        <div class="flex j-start p-lr-30 a-center">
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">手机: </div>
            <div class="item value f12">{{ formJson.Mobile }}</div>
          </div>
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">办公电话: </div>
            <div class="item value f12">{{ formJson.OfficeTel }}</div>
          </div>
        </div>
        <div class="flex j-start p-lr-30 a-center">
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">Email: </div>
            <div class="item value f12">{{ formJson.EMail }}</div>
          </div>
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">QQ号码: </div>
            <div class="item value f12">{{ formJson.QQ }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改个人信息 -->
    <!-- 企业信息修改 -->
    <el-dialog :visible="showSetInfo" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">修改个人信息</p>
        <p class="p-right-10 weight f16 cursor" style="color:#000" @click="close('ruleForm')">x</p>
      </div>
      <div class="m-top-20" />
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="登录账号：" prop="Province">
          {{ ruleForm.CompanyName }}
          <!-- <el-input v-model="ruleForm.Province" size="mini" /> -->
        </el-form-item>
        <el-form-item label="姓名：" prop="NickName">
          <el-input v-model="ruleForm.NickName" size="mini" />
        </el-form-item>
        <el-form-item label="手机：" prop="Mobile">
          <el-input v-model="ruleForm.Mobile" size="mini" />
        </el-form-item>
        <el-form-item label="Email：" prop="EMail">
          <el-input v-model="ruleForm.EMail" size="mini" />
        </el-form-item>
        <el-form-item label="性别：" prop="AddressCode">
          <el-radio v-model="ruleForm.Sex" :label="0">男</el-radio>
          <el-radio v-model="ruleForm.Sex" :label="1">女</el-radio>
        </el-form-item>
        <el-form-item label="办公电话：" prop="DeptName">
          <el-input v-model="ruleForm.OfficeTel" size="mini" />
        </el-form-item>
        <el-form-item label="QQ号码：" prop="ContactName">
          <el-input v-model="ruleForm.QQ" size="mini" />
        </el-form-item>
        <el-form-item>
          <el-button type="info" size="mini" @click="close('ruleForm')">取消</el-button>
          <el-button :loading="isWait" type="primary" size="mini" @click="submit('ruleForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Index from '@/minxin/userinfo/accountInfo'
export default Index
</script>
<style lang="scss" scoped>
.userinfo_header{
 width: 100%;
 height: 240px;
 background-color: #fff;
 border: 1px solid#dcdfe6;
  .header{
   height: 50px;
   background-color: rgb(239, 239, 239);
   line-height: 50px;
  }
  .header-icon{
    width: 200px;
    height: 90%;
    background-image: url('../../static/i.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .main_view{
    width: 100%;
    height: auto;
    .item_main{
       width: 50%;
      .item {
        height: 40px;
        line-height: 50px;
      }
      .title{
        color: #ccc;
      }
      .value {
        color: #999;
      }
    }
  }
}
</style>
